import apiInstance from '@/libs/axios'
import { MANAGER, MANAGER_DSP } from '@/constants/user-roles'

export const fetchUsers = (query) => apiInstance.get(`/api/user${query ? `?${query}` : ''}`)

export const createManager = (data) => apiInstance.post('/api/user', { ...data, role_id: MANAGER })

export const createManagerDSP = (data) => apiInstance.post('/api/user', { ...data, role_id: MANAGER_DSP })

export const updateManager = (data) => apiInstance.patch(`/api/user/${data.id}`, { ...data, role_id: MANAGER })

export const updateManagerDSP = (data) => apiInstance.patch(`/api/user/${data.id}`, { ...data, role_id: MANAGER_DSP })

export const activateUser = (id) => apiInstance.post(`/api/user/${id}/activate`)

export const deactivateUser = (id) => apiInstance.post(`/api/user/${id}/deactivate`)
