<template>
  <validation-observer v-slot="{ invalid }">
    <b-form @submit.prevent="handleSubmit">
      <validation-provider v-slot="{ errors }" name="Email" vid="email" :rules="validationSchema.email">
        <b-form-group label="Email" label-for="create-user-email">
          <b-form-input
            type="email"
            id="create-user-email"
            v-model="formData.email"
            placeholder="emial@example.com"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider v-slot="{ errors }" name="Имя" vid="name" :rules="validationSchema.name">
        <b-form-group label="Имя" label-for="create-user-name">
          <b-form-input
            id="create-user-name"
            placeholder="Введите имя"
            v-model="formData.name"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider v-slot="{ errors }" name="Пароль" vid="password" :rules="validationSchema.password">
        <b-form-group label="Пароль" label-for="create-user-password">
          <b-form-input
            placeholder="Введите пароль"
            id="create-user-password"
            v-model="formData.password"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <loader-button type="submit" variant="primary" :disabled="invalid || isSubmitting" :is-submitting="isSubmitting">
        {{ user.id ? 'Сохранить' : 'Создать' }}
      </loader-button>
    </b-form>
  </validation-observer>
</template>

<script>
import { BForm, BFormInput, BFormGroup, BInputGroup, BButton } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LoaderButton from '@/components/LoaderButton'
import { required, email, min } from '@validations'

export default {
  components: {
    LoaderButton,
    BForm,
    BButton,
    BFormInput,
    BInputGroup,
    BFormGroup,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    isSubmitting: {
      type: Boolean,
      required: false,
      default: () => false
    },
    validationSchema: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        email: this.user.email ?? '',
        name: this.user.name ?? '',
        password: this.user.password ?? '',
        is_active: this.user.is_active ?? true
      },

      required,
      email,
      min
    }
  },
  methods: {
    handleSubmit() {
      const requestParams = { ...this.formData }

      if (this.user.id) requestParams.id = this.user.id

      this.$emit('submit', { ...requestParams })
    }
  }
}
</script>
