<template>
  <div>
    <sidebar-right :visible="isOpenCreateForm" title="Создать пользователя" @hide="isOpenCreateForm = false">
      <div>
        <div class="my-2">
          <b-form-select v-model="createFormType" :options="roleId === 1 ? formTypeOptions : publisherFormTypeOptions" />
        </div>

        <create-user-form
          v-if="isOpenCreateForm"
          :forms="createFormsMap"
          :current-form="createFormType"
          @success="handleCreateUser"
          @error="handleError"
        />
      </div>
    </sidebar-right>

    <sidebar-right :visible="!!editUser" title="Редактировать пользователя" @hide="editUser = null">
      <div class="my-2">
        <create-user-form
          v-if="!!editUser"
          :current-form="editUser.role.id"
          :forms="editFormsMap"
          :form-props="{ user: editUser }"
          @success="handleUpdateUser"
          @error="handleError"
        />
      </div>
    </sidebar-right>

    <b-card no-body>
      <b-row class="my-2 px-2 d-flex justify-content-between">
        <b-col sm="2" class="mb-1 mb-md-0">
          <b-form-select v-model="queryObj.is_active" :options="filterOptions" />
        </b-col>
        <b-col v-if="can('manage', 'all')" sm="4" class="mb-1 mb-md-0">
          <DropDownFilter
              placeholder="Выберите роль"
              :multiple="false"
              :on-change="categoryChange"
              :facets="rolesOptions"
              :state="queryObj.role"
              track-by="id"  name="role"/>
        </b-col>
        <b-col sm="4" class="mb-1 mb-md-0">
          <b-input-group>
            <b-form-input placeholder="Поиск..." v-model="searchRef" @keyup="startSearch" />
            <b-input-group-append is-text>
              <b-icon-search></b-icon-search>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col v-if="can('create', 'users-table')" class="d-flex justify-content-end" sm="1">
          <b-button variant="primary" @click="isOpenCreateForm = true">Создать</b-button>
        </b-col>
      </b-row>
      <div ref="scroll">
      <table-lazy :is-loading="isLoading" :error="!!error" :is-loading-more="isLoadingMore">
        <users-table v-if="data && data.length" :users="data" @edit-user="handleEditUser" @toggle-active="handleToggleActive" />
      </table-lazy>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButtonGroup, BButton, BFormSelect, BSpinner, BInputGroup, BIconSearch } from 'bootstrap-vue'
import { MoreVerticalIcon } from 'vue-feather-icons'
import UsersTable from '@/components/UsersTable'
import DropDownFilter from "@/components/DropDownFilter"
import { getToastificationErrorParams, getToastificationSuccessParams } from '@/helpers/toasts'
import {ADMIN, MANAGER, MANAGER_DSP, PUBLISHER} from '@/constants/user-roles'
import CreatePublisherForm from '@/components/PartnerForm'
import CreateAdminForm from '@/components/AdminForm'
import SidebarRight from '@/components/SidebarRight'
import ManagerForm from '@/components/ManagerForm'
import CreateUserForm from '@/components/CreateUserForm'
import TableLazy from '@/components/TableLazy'
import { withFormData } from '@/hoc/withFormData'
import { usePage } from '@/use/page'
import { useUtils as useAclUtils } from '@core/libs/acl'
import {
  createManagerDSP,
  createManager,
  updateManager,
  updateManagerDSP,
  activateUser,
  deactivateUser,
  fetchUsers
} from '@/api/user'
import { createPublisher, updatePublisher } from '@/api/publisher'
import {
  CREATE_MANAGER_VALIDATION_SCHEMA,
  UPDATE_MANAGER_VALIDATION_SCHEMA,
  CREATE_PARTNER_VALIDATION,
  UPDATE_PARTNER_VALIDATION, CREATE_ADMIN_VALIDATION
} from '@/constants/validation'
import { IS_ACTIVE, IS_INACTIVE } from '@/constants/users'
import {infiniteScroll} from "@/use/infiniteScroll"
import {queries} from "@/use/queries"
import { search } from "@/use/search"
import {categoryHandler} from "@/use/categoryChange"
import {createAdmin, updateAdmin} from "@/api/admin"

const filterOptions = [
  { value: null, text: 'Все пользователи' },
  { value: 1, text: 'Активные' },
  { value: 0, text: 'Неактивные' }
]

const formTypeOptions = [
  { value: ADMIN, text: 'Администратор' },
  { value: MANAGER, text: 'Менеджер' },
  { value: MANAGER_DSP, text: 'Менеджер по DSP' }
]

const publisherFormTypeOptions = [
  { value: PUBLISHER, text: 'Паблишер' },
]

const createFormsMap = {
  [ADMIN]: withFormData(CreateAdminForm, createAdmin, CREATE_ADMIN_VALIDATION),
  // [PUBLISHER]: withFormData(CreatePublisherForm, createPublisher, CREATE_PARTNER_VALIDATION),
  [MANAGER]: withFormData(ManagerForm, createManager, CREATE_MANAGER_VALIDATION_SCHEMA),
  [MANAGER_DSP]: withFormData(ManagerForm, createManagerDSP, CREATE_MANAGER_VALIDATION_SCHEMA)
}

const editFormsMap = {
  [ADMIN]: withFormData(CreateAdminForm, updateAdmin, CREATE_ADMIN_VALIDATION),
  // [PUBLISHER]: withFormData(CreatePublisherForm, updatePublisher, UPDATE_PARTNER_VALIDATION),
  [MANAGER]: withFormData(ManagerForm, updateManager, UPDATE_MANAGER_VALIDATION_SCHEMA),
  [MANAGER_DSP]: withFormData(ManagerForm, updateManagerDSP, UPDATE_MANAGER_VALIDATION_SCHEMA)
}

export default {
  name: 'Users',
  components: {
    CreateUserForm,
    DropDownFilter,
    BCard,
    BIconSearch,
    BInputGroup,
    BRow,
    BCol,
    BFormInput,
    BButtonGroup,
    BButton,
    BFormSelect,
    BSpinner,
    MoreVerticalIcon,
    UsersTable,
    SidebarRight,
    TableLazy
  },
  setup() {
    const { isLoading, data, error, loadMoreData, isLoadingMore, fetchPageData } = usePage(fetchUsers)
    const { scroll } = infiniteScroll(loadMoreData, isLoadingMore)
    const { queryObj } = queries(fetchPageData)
    const { searchRef, startSearch } = search(queryObj)
    const { can } = useAclUtils()
    const { categoryChange } = categoryHandler(queryObj)


    const rolesOptions = [
      { id:'manager', name: "Менеджер" },
      { id:'manager_dsp', name: "Менеджер DSP" },
    ]

    return {
      isLoading,
      data,
      error,
      loadMoreData,
      isLoadingMore,
      fetchPageData,
      can,
      scroll,
      queryObj,
      searchRef,
      startSearch,
      rolesOptions,
      categoryChange
    }
  },
  data() {
    return {
      roleId: null,
      createFormType: ADMIN,
      filterOptions,
      isOpenCreateForm: false,
      editUser: null,
      createFormsMap,
      formTypeOptions,
      publisherFormTypeOptions,
      editFormsMap
    }
  },
  watch: {
    error(message) {
      this.$toast(getToastificationErrorParams(message))
    }
  },
  created() {
    this.roleId = parseInt(localStorage.getItem('userRoleId'), 10);
  },
  methods: {
    handleError(e) {
      this.$toast(getToastificationErrorParams(e.message))
    },
    refetchTable() {
      this.isOpenCreateForm = false
      this.editUser = null
      this.fetchPageData()
    },
    handleEditUser(id) {
      this.editUser = this.data.find((u) => u.id === id)
    },
    handleCreateUser() {
      this.$toast(getToastificationSuccessParams('Пользователь успешно создан'))
      this.refetchTable()
    },
    handleUpdateUser() {
      this.$toast(getToastificationSuccessParams('Пользователь успешно обновлен'))
      this.refetchTable()
    },
    handleToggleActive(id) {
      const userIndex = this.data.findIndex((u) => u.id === id)
      const user = this.data[userIndex]
      const request = user.is_active ? activateUser : deactivateUser
      request(id)
        .then(() => {
          this.$toast(getToastificationSuccessParams('Данные обновлены'))
        })
        .catch((e) => this.$toast(getToastificationErrorParams(e.message)))
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect, .multiselect__input, .multiselect__single {
  font-size: 14px;
}
</style>

