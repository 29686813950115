<template>
  <div>
    <component
      :is='form'
      :data='{roleId, ...formProps}'
      @success="handleCreateUser"
      @error="handleCreateUserError"
    />
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'

export default {
  name: 'CreateUserForm',
  components: {
    BFormSelect
  },
  props: {
    isSubmitting: Boolean,
    forms: {
      type: Object,
      required: true,
    },
    currentForm: {
      type: Number,
      required: true,
    },
    formProps: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    form() {
      return this.forms[this.currentForm]
    }
  },
  data() {
    return {
      roleId: null,
    }
  },
  created() {
    this.roleId = parseInt(localStorage.getItem('userRoleId'), 10)
  },
  methods: {
    handleCreateUser() {
      this.$emit('success')
    },
    handleCreateUserError(e) {
      this.$emit('error', e)
    }
  },
}

</script>
