<template>
  <validation-observer v-slot="{ invalid }">
    <b-form class="py-2" @submit.prevent="handleSubmit">
      <validation-provider v-slot="{ errors }" name="Имя" vid="name" :rules="validationSchema.name">
        <b-form-group label="Имя" label-for="publisher-name">
          <b-form-input id="publisher-name" v-model="formData.name" :state="errors.length > 0 ? false : null" />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider v-slot="{ errors }" name="Email" vid="userEmail" :rules="validationSchema.email">
        <b-form-group label="Email" label-for="publisher-email">
          <b-form-input id="publisher-email" v-model="formData.email" :state="errors.length > 0 ? false : null" />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider v-slot="{ errors }" name="Пароль" vid="password" :rules="validationSchema.password">
        <b-form-group label="Пароль" label-for="publisher-password">
          <b-form-input id="publisher-password" v-model="formData.password" :state="errors.length > 0 ? false : null" />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <loader-button type="submit" variant="primary" :disabled="invalid || isSubmitting" :is-submitting="isSubmitting">
        {{ user.id ? 'Сохранить' : 'Создать' }}
      </loader-button>
    </b-form>
  </validation-observer>
</template>

<script>
import { BForm, BFormSelect, BFormGroup, BFormInput, BButton, BFormDatepicker } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LoaderButton from '@/components/LoaderButton'
import { required, email, min, length, max } from '@validations'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    LoaderButton
  },
  props: {
    isSubmitting: {
      type: Boolean,
      required: false,
      default: () => false
    },
    validationSchema: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: false,
      default: () => ({})
    },
    data: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        name: this.user.name ?? '',
        email: this.user.email ?? '',
        password: this.user.password ?? '',
        is_active: this.user.is_active ?? true,
        role_id: 1
      },

      // Validations
      email,
      min,
      length,
      required,
      max
    }
  },
  methods: {
    handleSubmit() {
      const submitData = { ...this.formData }

      if (this.user.id) submitData.id = this.user.id

      this.$emit('submit', { ...submitData })
    }
  },
}
</script>
