var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":_vm.validationSchema.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"create-user-email"}},[_c('b-form-input',{attrs:{"type":"email","id":"create-user-email","placeholder":"emial@example.com","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Имя","vid":"name","rules":_vm.validationSchema.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Имя","label-for":"create-user-name"}},[_c('b-form-input',{attrs:{"id":"create-user-name","placeholder":"Введите имя","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Пароль","vid":"password","rules":_vm.validationSchema.password},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Пароль","label-for":"create-user-password"}},[_c('b-form-input',{attrs:{"placeholder":"Введите пароль","id":"create-user-password","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('loader-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.isSubmitting,"is-submitting":_vm.isSubmitting}},[_vm._v(" "+_vm._s(_vm.user.id ? 'Сохранить' : 'Создать')+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }