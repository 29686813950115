<template>
  <b-table :items="users" :fields="fields">

    <template #cell(id)="row">
      <p style="word-break: normal; margin: 0;">{{ row.item.id }}</p>
    </template>


    <template #cell(role)="row">
      <span>{{ t(row.item.role.name) }}</span>
    </template>

    <template #cell(status)="row">
<!--      <b-badge pill :variant="row.item.is_active ? 'light-success' : 'light-danger'">-->
<!--        {{ row.item.is_active ? 'Активен' : 'Неактивен' }}-->
<!--       -->
<!--      </b-badge>-->
      <b-form-checkbox v-model="row.item.is_active" variant="success" switch @change="handleToggleActive($event, row.item.id)" />
    </template>

    <template #cell(actions)="row">
      <b-dropdown right variant="flat-primary" no-caret>
        <template #button-content>
          <more-vertical-icon />
        </template>

        <b-dropdown-item v-if="row.item.role.name === 'Manager'" :to="{ name: 'platformsUser', params: { user_id: row.item.id }}">Площадки</b-dropdown-item>

        <b-dropdown-item v-if="row.item.role.name === 'Manager'" :to="{ name: 'placementsUser', params: { user_id: row.item.id }}">Размещения</b-dropdown-item>

        <b-dropdown-item v-if="row.item.role.name === 'Manager DSP'" :to="{ name: 'advertising-campaignsAdvertiser', params: { user_id: row.item.id }}">РК</b-dropdown-item>

        <b-dropdown-item v-if="row.item.role.name === 'Manager DSP'" :to="{ name: 'advertisersUser', params: { user_id: row.item.id }}">Рекламодатели</b-dropdown-item>

        <b-dropdown-divider v-if="row.item.role.name !== 'Admin'" />

        <b-dropdown-item @click="handleEditUser(row.item.id)"> Редактировать</b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>

<script>
import { BTable, BCard, BRow, BCol, BButton, BBadge, BDropdown, BDropdownItem, BDropdownDivider, BFormCheckbox } from 'bootstrap-vue'
import { MoreVerticalIcon } from 'vue-feather-icons'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

const fields = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: 'Имя' },
  { key: 'email', label: 'email' },
  { key: 'role', label: 'Роль' },
  { key: 'status', label: 'Статус' },
  { key: 'actions', label: 'Действие' }
]

export default {
  components: {
    BTable,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    MoreVerticalIcon
  },
  setup() {
    const {t} = useI18nUtils()

    return {t}
  },
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      fields
    }
  },
  methods: {
    handleEditUser(id) {
      this.$emit('edit-user', id)
    },
    handleToggleActive(state, id) {
      this.$emit('toggle-active', id)
    },
  }
}
</script>
